import CuteBanner from "./CuteBanner/CuteBanner";

function App() {
  return (
    <div className="App bg-white h-screen">
      <div className="max-w-4xl mx-auto pt-5 px-5 md:px-0">
        <CuteBanner />
      </div>
    </div>
  );
}

export default App;
