import React, { useEffect, useState, useRef } from "react";

const CuteBanner = () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [slideIsRunning, setSlideIsRunning] = useState(false);
  const slideRunningRef = useRef(false);

  const slideRef = useRef();
  const [isAuto, setIsAuto] = useState(true); // is auto slide
  const totalSlides = 3; //
  const slideSpeed = 800; // speed of carousel (miliseconds)

  // dot pressing
  const handleBanner = (bannerId, autoSlide = true) => {
    if (!autoSlide && !slideRunningRef.current) {
      setIsAuto(false);
      clearInterval(slideRef.current);
    }
    if (!slideRunningRef.current) {
      setSlideIsRunning(() => true);
      slideRunningRef.current = true;
      setCurrentSlide(bannerId);
      setTimeout(() => {
        setSlideIsRunning(() => false);
        slideRunningRef.current = false;
      }, slideSpeed);
    } else {
      console.log("not running", slideRunningRef.current);
    }
  };

  useEffect(() => {
    if (isAuto) {
      const loopSlide = setInterval(() => {
        let nextSlide = currentSlide + 1;
        if (currentSlide === totalSlides) {
          nextSlide = 1;
        }
        handleBanner(nextSlide);
      }, 3000);
      slideRef.current = loopSlide;
    }

    return () => {
      clearInterval(slideRef.current);
    };
  }, [isAuto, currentSlide]);

  return (
    <>
      <div className="bg-gray-200 border-2 border-gray-200 shadow-xl rounded-2xl overflow-hidden">
        <div className="sm:flex md:items-stretch">
          <div className="md:w-1/2  md:order-2 ">
            <div className="h-72 md:h-96 flex items-center justify-end relative overflow-hidden">
              <img
                src="/gusttavo-lima.jpg"
                alt="Slide 1"
                className={`md:h-full md:w-auto text-5xl text-gray-900 font-bold absolute top-0  ${
                  currentSlide === 1
                    ? "translate-x-0 scale-100 opacity-100 delay-500"
                    : slideIsRunning
                    ? "-translate-x-28 scale-50 opacity-0"
                    : "translate-x-28 scale-50 opacity-0"
                } transition ease-out duration-${slideSpeed}`}
              />
              <img
                src="/gusttavo-lima.jpg"
                alt="Slide 1"
                className={`md:h-full md:w-auto  text-5xl text-gray-900 font-bold absolute top-0  ${
                  currentSlide === 2
                    ? "translate-x-0 scale-100 opacity-100 delay-500"
                    : slideIsRunning
                    ? "-translate-x-28 scale-50 opacity-0"
                    : "translate-x-28 scale-50 opacity-0"
                } transition ease-out duration-${slideSpeed}`}
              />
              <img
                src="/gusttavo-lima.jpg"
                alt="Slide 1"
                className={`md:h-full md:w-auto  text-5xl text-gray-900 font-bold absolute top-0  ${
                  currentSlide === 3
                    ? "translate-x-0 scale-100 opacity-100 delay-500"
                    : slideIsRunning
                    ? "-translate-x-28 scale-50 opacity-0"
                    : "translate-x-28 scale-50 opacity-0"
                } transition ease-out duration-${slideSpeed}`}
              />
            </div>
          </div>
          <div className="md:w-1/2 px-3 md:px-10 py-3 md:py-10 flex flex-col justify-between overflow-hidden md:order-1">
            <div className="border relative h-20 md:h-full">
              <h1
                className={`text-2xl md:text-5xl w-full text-gray-900 font-bold absolute top-0 text-center md:text-left ${
                  currentSlide === 1
                    ? "md:-translate-x-0 scale-100 opacity-100 delay-500"
                    : "md:-translate-x-64 scale-50 opacity-0"
                } transition ease-out duration-${slideSpeed}`}
              >
                CONTRATE AGORA MESMO ESTE PRODUTO
              </h1>
              <h1
                className={`text-2xl md:text-5xl w-full text-gray-900 font-bold absolute top-0 text-center md:text-left ${
                  currentSlide === 2
                    ? "md:-translate-x-0 scale-100 opacity-100 delay-500"
                    : "md:-translate-x-64 scale-50 opacity-0"
                } transition ease-out duration-${slideSpeed}`}
              >
                22222
              </h1>
              <h1
                className={`text-2xl md:text-5xl w-full text-gray-900 font-bold absolute top-0 text-center md:text-left ${
                  currentSlide === 3
                    ? "md:-translate-x-0 scale-100 opacity-100 delay-500 text-red-900"
                    : "md:-translate-x-64 scale-50 opacity-0 text-gray-900"
                } transition ease-out duration-${slideSpeed}`}
              >
                33333
              </h1>
            </div>

            <nav className="">
              <ul className="flex space-x-2 justify-center md:justify-start">
                <li>
                  <button
                    type="button"
                    onClick={() => handleBanner(1, false)}
                    className={`h-3 bg-gray-700 rounded-full hover:opacity-100 ${
                      currentSlide === 1 ? "w-10 opacity-100" : "w-3 opacity-60"
                    } transition-all ease-out`}
                  />
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => handleBanner(2, false)}
                    className={`h-3 bg-gray-700 rounded-full hover:opacity-100 ${
                      currentSlide === 2 ? "w-10 opacity-100" : "w-3 opacity-60"
                    } transition-all ease-out`}
                  />
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => handleBanner(3, false)}
                    className={`h-3 bg-gray-700 rounded-full hover:opacity-100 ${
                      currentSlide === 3 ? "w-10 opacity-100" : "w-3 opacity-60"
                    } transition-all ease-out`}
                  />
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/* <h1>Banner</h1> */}
      </div>
    </>
  );
};

export default CuteBanner;
